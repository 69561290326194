import React, {useState} from "react"
import { navigate } from "gatsby"
import { CardBody, Card, Container, Button, Input, Dropdown, DropdownMenu, DropdownItem } from "reactstrap"
import { useEntityInsertionById, wrapUserConsumer } from "../components/user-context"
import { localStorageReadItem } from "../components/masquerade"


function newConversation({user, location, data}){


  let userId = location.hasOwnProperty('state') && location.state != null && location.state.hasOwnProperty('userId')  ? location.state.userId : '';
  let userName = location.hasOwnProperty('state') && location.state != null && location.state.hasOwnProperty('name')  ? location.state.name : '';

  const [sectionInput, setSectionInput] = useState("");
  const [matchingSections, setMatchingSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState({id:"",title:""});
    
  //Handler for creating new conversations
  let conversationHandler = user ? user.getEntityUpdateHandler() : null;
  let conversationBody = {
    data: {
      type: "node--conversation",
      attributes: {
        title: "Test conversation for section " + selectedSection.id
      },
      relationships: {
        field_user_permission: {
          data: {
            type: "user--user",
            id: userId,
            meta: {
              grant_public: false,
              grant_view: true,
              grant_update: 0,
              grant_delete: 0,
            }
          }
        },
        field_related_section: {
          data: {
            type: "node--section",
            id: selectedSection.id
          }
        }
      }
    }
  }

  /*
    input handler when filtering sections to attach to the conversation
  */
  async function sectionInputChange(event){
    event.preventDefault();
    const sectionInput = event.target.value;
    setSectionInput(sectionInput);

    if(sectionInput !== ""){
      const sectionNodes = data.allNodeSection.nodes;

      const sectionPattern = new RegExp(sectionInput.toLowerCase(),"g");

      const matchingSections = sectionNodes.filter( section => {
        return section.title.toLowerCase().match(sectionPattern);
      });
      setMatchingSections(matchingSections);
    }else{
      setMatchingSections([]);
    }
  }

  /*
    Input handler when selecting section to attach to the conversation
  */
  async function sectionSelect(event){
    event.preventDefault();
    const selectedSectionId = event.target.id
    const selectedSectionName = event.target.name;
    setSelectedSection({title: selectedSectionName, id: selectedSectionId});
    setSectionInput("");
    setMatchingSections([]);
  }

  /*
    Event handler when you finalize the creation of the conversation
  */
  async function createConversation(event){
    event.preventDefault();
    if(selectedSection.id && userId && conversationHandler){
      conversationHandler.getLocalId();
      conversationHandler.handle({
        body: conversationBody,
        insertIntoUserData: useEntityInsertionById('conversation'),
        identifyingPropertyString: 'conversation.local_id',
        identifyingPropertyCondition: conversationHandler.localId,
      });
      navigate("/conversations", {state:{name:userName, localConversationID: conversationHandler.localId}});
      /*
        since the handler doesn't tell us anything about what happened we can't really conditionally redirect
        without looking through the user data and figuring out if this entity was created.
        So we will just go to the conversations page
      */

    }
  }


  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  /*
    The set of elements rendered when you want to filter for a section
  */
  const selectSectionElement = (
    <div>
      <div>Select the section you wish to talk about</div>
      <Input placeholder="Talk about section:" autoComplete="off" type="text" name="section" value={sectionInput} onChange={sectionInputChange}/>
      { matchingSections.length <= 0 ? null : (
        <Dropdown isOpen={true} active inNavbar={true} toggle={toggle}>
          <DropdownMenu active>
            {matchingSections.map(section => (
                <DropdownItem key={section.drupal_id} name={section.title} id={section.drupal_id} onClick={sectionSelect}>{section.title}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  );

  /*
    The set of elements rendered when you have selected a section but still need to confirm
  */
  const startConversationButton = (
    <div>
      <div>You are about to start a conversation with {userName} about {selectedSection.title}</div>
      <Button onClick={createConversation}>Start new Conversation</Button><Button onClick={() => {setSelectedSection({title:"",id:""})}}>Change Section</Button>
    </div>
  );

  return (
    <Container className="mt--6" fluid>
      <Card>
        <CardBody>
          {selectedSection.id == "" ? selectSectionElement : startConversationButton}
        </CardBody>
      </Card>
    </Container>
  );
}

export const query = graphql`
  query ConversationQuery {
    allNodeSection(filter: {queriedLanguage: {eq: "en"}}) {
      nodes {
        title
        drupal_id
      }
    }
  }
`;

export default wrapUserConsumer(newConversation);
